
export default {
  props: {
    activeTab: { type: String, default: 'books' },
    description: { type: String, default: null },
    contentType: { type: String, default: 'book' },
    format: { type: String, default: 'ebook' },
  },

  data() {
    return {
      categories: [],
      isLoading: true,
    }
  },

  async fetch() {
    const categories = await this.$hummingbird.catalog
      .getCategories(this.contentType, this.format)
      .then(resp => {
        return resp.data.categories
      })
      .catch(err => {
        this.$sentry.captureException(err)
      })

    this.isLoading = false
    this.categories = categories
  },
}
