import { render, staticRenderFns } from "./CatalogCategories.vue?vue&type=template&id=0fa8a97a&"
import script from "./CatalogCategories.vue?vue&type=script&lang=js&"
export * from "./CatalogCategories.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CatalogCategoriesSkeleton: require('/app/components/Catalog/CatalogCategoriesSkeleton.vue').default,CatalogCategoryTopLevelCard: require('/app/components/Catalog/CatalogCategoryTopLevelCard.vue').default})
