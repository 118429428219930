
import { createSlug } from '~/helpers/slug'

export default {
  props: {
    category: {
      type: Object,
      required: true,
    },

    type: {
      type: String,
      required: true,
      validator: value => ['books', 'magazines'].includes(value),
    },
  },

  data() {
    return {
      imageWidth: 308,
    }
  },

  computed: {
    imageHeight() {
      if (!this.category.small_image) {
        // If no image, make the card shorter
        return 105
      }

      return 246
    },
  },

  methods: {
    createSlug,

    categoryImage(url, width, height) {
      if (!url) {
        // Some categories, like magazines categories, don't have an image (yet)
        return ''
      }

      const imageURL = new URL(url)

      imageURL.searchParams.set('auto', 'format')
      imageURL.searchParams.set('w', width)
      imageURL.searchParams.set('h', height)

      return imageURL
    },
  },
}
